<template>
    <div class="flex flex-col md:flex-row max-w-screen-lg pt-40 md:pt-20 pb-20 mx-auto gap-10 items-center px-10 lg:px-4 overflow-x-hidden">
        <div class="flex flex-row flex-1 scale-150 md:scale-100">
            <div class="bg-gray-400 scale-90 rounded-xl md:rounded-2xl w-full drop-shadow-2xl translate-x-10 -z-20 border border-black flex-1">
                <nuxt-img format="webp" :src="$t('home.features.health.image_heart')" width="240" height="520" quality="80" loading="lazy" alt="smoxy app screen analyse" class="w-full h-full rounded-xl md:rounded-2xl" /> 
            </div>   
            <div class="bg-gray-400 rounded-xl md:rounded-2xl w-full drop-shadow-2xl border border-black flex-1">
                <nuxt-img format="webp" :src="$t('home.features.health.image_nicotine')"  width="240" height="520" quality="80" loading="lazy" alt="smoxy app screen analyse" class="w-full h-full rounded-xl md:rounded-2xl" /> 
            </div>
            <div class="bg-gray-400 rounded-xl scale-90 md:rounded-2xl w-full drop-shadow-2xl -translate-x-10 -z-20 border border-black flex-1">
                <nuxt-img format="webp" :src="$t('home.features.health.image_lung')" width="240" height="520" quality="80" loading="lazy" alt="smoxy app screen analyse" class="w-full h-full rounded-xl md:rounded-2xl" /> 
            </div>
        </div>
        
        <div class="pt-10 md:pt-0 px-4 md:px-0 flex-1">
            <p class="text-[#7E1EF8]">{{$t('home.features.health.title')}}</p>
            <h3 class="text-3xl pr-4 font-bold font-owners text-[#290B50]">{{$t('home.features.health.headline')}}</h3>
            <p class="pt-4">{{$t('home.features.health.body')}}</p>
        </div>  
    </div>
</template>